// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/builds/weareredlight/anotado-brand/landing-page/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-autores-js": () => import("/builds/weareredlight/anotado-brand/landing-page/src/pages/autores.js" /* webpackChunkName: "component---src-pages-autores-js" */),
  "component---src-pages-confirm-index-js": () => import("/builds/weareredlight/anotado-brand/landing-page/src/pages/confirm/index.js" /* webpackChunkName: "component---src-pages-confirm-index-js" */),
  "component---src-pages-index-js": () => import("/builds/weareredlight/anotado-brand/landing-page/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parceiros-js": () => import("/builds/weareredlight/anotado-brand/landing-page/src/pages/parceiros.js" /* webpackChunkName: "component---src-pages-parceiros-js" */),
  "component---src-pages-politicadeprivacidade-js": () => import("/builds/weareredlight/anotado-brand/landing-page/src/pages/politicadeprivacidade.js" /* webpackChunkName: "component---src-pages-politicadeprivacidade-js" */),
  "component---src-pages-produtos-js": () => import("/builds/weareredlight/anotado-brand/landing-page/src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-reset-index-js": () => import("/builds/weareredlight/anotado-brand/landing-page/src/pages/reset/index.js" /* webpackChunkName: "component---src-pages-reset-index-js" */),
  "component---src-pages-termosecondicoes-js": () => import("/builds/weareredlight/anotado-brand/landing-page/src/pages/termosecondicoes.js" /* webpackChunkName: "component---src-pages-termosecondicoes-js" */)
}

exports.data = () => import("/builds/weareredlight/anotado-brand/landing-page/.cache/data.json")

